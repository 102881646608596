
export default {
  name: 'TermsPage',
  head () {
    return {
      title: "قوانین و شرایط استفاده",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "تتر اکسچنج | لیست مفاد قرار داد برای کاربران جهت استفاده راحت تر از وب سایت"
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "قوانین و مقررات تتر اکسچنج"
        },
        { hid: "logo", name: "image", content: "~/layouts/logos/default.svg" },
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "googlebot", content: "index,follow" },
      ],
      link: [
        {
          hid: 'canonical',
          rel: "canonical",
          hreflang: "fa",
          href: 'https://tether-ex.com/terms',
        },
      ],
    }
  },
}
